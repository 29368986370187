import axios from 'axios'
import { withTrailingSlash } from 'ufo'

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) return
  const config = useRuntimeConfig()
  const { apiBaseURL: apiUrl } = config.public
  const { baseUrl: appBaseUrl } = config.public

  const oldPermalink = withTrailingSlash(`${appBaseUrl}${to.fullPath}`)

  try {
    const oldPermalinksMap = await axios.get(
      `${apiUrl}/v1/store/generate-old-links-relation/`
    )

    if (oldPermalinksMap.data[oldPermalink] !== undefined) {
      const url = oldPermalinksMap.data[oldPermalink]
      return navigateTo({ path: url }, { redirectCode: 301 })
    }
  } catch (e) {
    console.error(e)
  }
})
